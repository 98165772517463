<template>
 <div>
    <a-modal :visible="visible" :title="title" width="80%" @cancel="cancel" :footer="null">
      <div v-if="!userMsg.customerName" class="flex ju-center m-t4">
        <a-spin size="large" />
      </div>
      <div v-else>
        <div class="infoBox flex al-center pos-rel">
          <div style="margin-right: 100px;">客户名称：{{ userMsg.customerName }}</div>
          <div style="margin-right: 100px;">开票人：{{ userMsg.sponsor }} </div>
          <span>开票金额：￥{{ userMsg.amt }}</span>
          <div class="pos-abs" style="right: 10px;">
            <span v-if="lookItem.checkInvoiceType?.value==2&&lookItem.cancelResult?.value == 1"> <a-button @click="cancelAdd(lookItem)" type="primary">作废</a-button></span>
          <span v-if="lookItem.checkInvoiceType?.value==3&&lookItem.cancelResult?.value == 1"><a-button @click="cancelAdd(lookItem)" type="primary">红冲</a-button></span>

          </div>
        </div>
        <div v-if="userMsg.invoiceNum">
          <div class="tips m-t3 m-b2">
            <span>开票结果</span>
          </div>
          <div class="flex wrap" >
            <div style="width: 25%;">发票号：{{userMsg.invoiceNum  }}</div>
            <div   style="width: 25%;">发票： <a v-if="userMsg.invoiceFileId" @click="lookImg('invoiceFileId',userMsg)">查看</a></div>
            <div  style="width: 25%;">开票人：{{ userMsg.sponsor }}</div>
            <div  style="width: 25%;">开票时间：{{ userMsg.invoiceFileTime }}</div>
            <div class="m-t1">备注：{{ userMsg.processRemark }}</div>
          </div>
        </div>
        <div v-if="userMsg?.cancelInvoiceFileTime || userMsg?.cancelInvoiceFileId">
          <div class="tips m-t3 m-b2">
            <span>结果</span>
          </div>
          <div class="flex wrap" >
            <div style="width: 25%;">关联流程编号：{{userMsg.frontFlowInstanceId  }}</div>
            <div   style="width: 25%;">凭证： <a v-if="userMsg.cancelInvoiceFileId" @click="lookImg('cancelInvoiceFileId',userMsg)">查看</a></div>
            <div  style="width: 25%;">处理人：{{ userMsg.handler }}</div>
            <div  style="width: 25%;" v-if="title == '作废明细'">作废时间：{{ userMsg.cancelInvoiceFileTime }}</div>
            <div  style="width: 25%;" v-else>红冲时间：{{ userMsg.cancelInvoiceFileTime }}</div>
            <div class="m-t1">备注：{{ userMsg.cancelProcessRemark }}</div>
          </div>
        </div>
        <div class="contentBox m-t2">
          <div class="tips ">
            <span>开票车辆（共{{ dataList.length }}辆）</span>
          </div>
          <div class="m-t1">
            <a-table size="small" :pagination="false" :loading="detailLoading" :rowKey="(record, index) => { return index;}" bordered :columns="columns" :data-source="dataList">
              <template #settlementStatus="{ record }">
                 {{ record.settlementStatus?.label }}
              </template>
              <template #vinNo="{ record }">
                 {{ record.vinNo}} {{ record.brand }} {{ record.model }}
              </template>
              <template #customRender="{ record }">
                  {{ record.settlementStatus?.label }}
              </template>
              <template #taxRate="{ record }">
                 <span v-if="record.taxRate">{{ record.taxRate }}%</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <PictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></PictureViewer>
    </a-modal>

    <CancellationModal v-model:visible="cancellationVisible" :cancelInfo="cancelInfo" @handok="handok"></CancellationModal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { billInvoiceInvoiceId, InvoiceProvide} from '@/api/finance/finance.js';
import PictureViewer from '@/components/PictureViewer';
import  CancellationModal  from '../cancellation'
import { start } from '@/api/system/bpm/instance'
import { message } from 'ant-design-vue'
const props = defineProps({
  visible:{
    type:Boolean,
    default:false
  },
  lookID:{
    type:String,
    default:''
  },
  lookItem:{
    type:Object,
    default:{}
  }
})
const emit = defineEmits(['update:visible','redPush'])
const cancelInfo = ref({})
const visibleImgPic = ref(false)
const cancellationVisible = ref(false)
const lookImgID = ref([])
const userMsg = ref({})
const dataList = ref([])
const title = ref('')
const detailLoading = ref(false)
// 预览图片
const lookImg = (str,item) => {
  if(typeof item[str] === 'string'){
   lookImgID.value = [item[str]]
   visibleImgPic.value = true
 }else{
   lookImgID.value = item[str]
   visibleImgPic.value = true
 }

}
const reqBillInvoiceInvoiceId = () => {
  detailLoading.value = true
  billInvoiceInvoiceId(props.lookID).then(res => {
    detailLoading.value = false
    if (res.code == 10000) {
      userMsg.value = res.data
      dataList.value = res.data.details
      console.log('数据',res);
    }else{
      userMsg.value = {}
      dataList.value = []
    }

  })
}
const cancelAdd = (item) =>  {
 InvoiceProvide(item.id).then( res => {
  cancelInfo.value = res.data
  cancellationVisible.value = true;
 })

}
const handok = (val) =>{
  Object.assign(cancelInfo.value,val)
  let obj = JSON.parse(JSON.stringify(cancelInfo.value))
  delete obj.flowInstanceId
  delete obj.invoiceMethod
  delete obj.ticketCategory
  delete obj.applyInvoiceType
  console.log(cancelInfo);
  let type = ''
  if(cancelInfo.value.applyInvoiceType?.value ==  2){
    type = 7
  }
  if(cancelInfo.value.applyInvoiceType?.value ==3){
    type = 8
  }
  let msg = {
    bizParams:obj,
    type:type
  }
  console.log(msg);
  start(msg).then(res => {
    if(res.code !== 10000) return
    cancellationVisible.value = false
    cancel()
    message.success('操作成功')
    emit('redPush')
  })
}
const cancel = () => {
  emit('update:visible', false)
}
const columns = ref([
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key: 'customerName',
    align: 'center',

  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key: 'vinNo',
    align: 'center',
    slots:{
      customRender: 'vinNo'
    }
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    key:'orderId',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    key: 'salesman',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align: 'center',
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key: 'settlementStatus',
    slots:{ customRender: 'settlementStatus' },
    align: 'center',
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key:'invoiceCarrier',
    align: 'center',
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    key: 'taxRate',
    align: 'center',
    slots:{ customRender:'taxRate'}
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    key: 'taxItem',
    align: 'center',
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmt',
    key: 'invoiceAmt',
    align: 'center',
  },
]);
onMounted(() => {
  reqBillInvoiceInvoiceId()
  let lookStatusID = props.lookItem.applyInvoiceType.value
  if(lookStatusID == 1){
    title.value = '开票明细'
  }else if(lookStatusID== 2){
    title.value = '作废明细'
  }else if(lookStatusID== 3 ){
    title.value = '红冲明细'
  }
})
</script>

<style lang="less" scoped>
.infoBox {
  width: 100%;
  padding: 25px 10px;
  border: 1px solid rgb(193, 199, 208);
}
</style>

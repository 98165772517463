<template>
  <div>
    <a-modal :visible="visible" title="开票明细" width="85%" @cancel="cancel" @ok="handleOk" :footer="null">
      <div v-if="!userMsg.customerName" class="flex ju-center m-t4">
        <a-spin size="large" />
      </div>
      <div v-else>
        <div class="infoBox flex al-center pos-rel">
          <div style="margin-right: 100px;">客户名称：{{ userMsg.customerName }}</div>
          <div style="margin-right: 100px;">开票人：{{ userMsg.sponsor }} </div>
          <span>开票金额：￥{{ userMsg.amt }}</span>
          <div class="pos-abs" style="right: 10px;" v-if="!onlyDisplay">
            <a-button @click="sponsorAdd" type="primary">发起开票</a-button>
          </div>
        </div>
        <div class="contentBox">
          <div class="tips flex ju-between ">
            <span>开票车辆（共{{ dataList.length }}辆）</span>
            <a-button v-if="!onlyDisplay" class="m-r3" @click="addOrden" type="primary">添加车辆</a-button>
          </div>
          <div>
            <a-table size="small" :pagination="false" :loading="detailLoading" :rowKey="(record, index) => { return index;}" bordered :columns="columns" :data-source="dataList">
              <template #settlementStatus="{ record }">
                {{ record.settlementStatus?.label }}
              </template>
              <template #vinNo="{ record }">
                {{ record.vinNo}} {{ record.brand }} {{ record.model }}
              </template>
              <template #customRender="{ record }">
                {{ record.settlementStatus?.label }}
              </template>
              <template #taxRate="{ record }">
                <span v-if="record.taxRate">{{ record.taxRate }}%</span>
              </template>
              <template #add="{ record }">
                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="confirm(record)">
                  <template #title>
                    您确定要删除吗？
                  </template>
                  <span v-if="!onlyDisplay" class=" cur-p" style="color:red">删除</span>
                </a-popconfirm>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- //发起开票弹窗 -->
    <a-modal v-model:visible="sponsorVisible" title="发起开票"  width="40%" @ok="handleOk">
      <div class="m-b0-5">开票主体：{{userMsg.invoiceCarrier}} </div>
      <div class="m-b0-5">开票税率：<span v-if="userMsg.taxRate">{{userMsg.taxRate}}%</span> </div>
      <div class="m-b0-5">开票税目：{{ userMsg.taxItem }} </div>
      <div class="flex ju-between  m-b1"><span><span style="color: red;font-family: SimSun, sans-serif;">*</span>开票抬头：</span><span><a class="m-r2" @click="newTitle">新增抬头</a><a v-if="listData.length>0" @click="changeTitle">更换抬头</a></span></div>
      <div class="cardBox m-b1" v-if="invoiceInfo.invoiceType">
        <div class="title">
          抬头类型：<span v-if="invoiceInfo.invoiceType">{{invoiceInfo.invoiceType.label }}</span>
        </div>
        <div class="flex wrap" style="padding:10px 20px;">
          <span style="width: 60%;" class="m-b0-5">名称：{{ invoiceInfo.name }}</span>
          <span class="m-b0-5">税号：{{ invoiceInfo.taxIdentNum }}</span>
          <span class="m-b0-5" style="width: 60%;">电话：{{ invoiceInfo.phone }}</span>
          <span class="m-b0-5">地址：{{ invoiceInfo.address }}</span>
          <span class="m-b0-5" style="width: 60%;">开户行：{{ invoiceInfo.bank }}</span>
          <span class="m-b0-5">银行账号：{{ invoiceInfo.account }}</span>
          <div v-if="invoiceInfo.invoiceType" style="height: 50px;width: 100%;" class="flex wrap">
            <span class="m-b0-5" style="width: 60%;">开票资料截图：<a v-if="invoiceInfo.screenshotIds.length>0" @click="lookImg('screenshotIds',invoiceInfo)">查看</a></span>
            <span class="m-b0-5" v-if="invoiceInfo.invoiceType.value == 3">一般纳税人资格证：<a v-if="invoiceInfo.taxpayerFileId" @click="lookImg('taxpayerFileId',invoiceInfo)">查看</a></span>
            <span style="width: 60%;" v-if="invoiceInfo.invoiceType.value == 3">营业执照：<a v-if="invoiceInfo.licenseFileId" @click="lookImg('licenseFileId',invoiceInfo)">查看</a></span>
            <span class="m-b0-5" v-if="invoiceInfo.invoiceType.value == 2">组织机构代码证：<a v-if="invoiceInfo.licenseFileId" @click="lookImg('licenseFileId',invoiceInfo)">查看</a></span>
          </div>
        </div>
      </div>
    <div v-show="invoiceInfo.invoiceType">
      <div>
        <div><span style="color: red;font-family: SimSun, sans-serif;">*</span>开票类型：</div>
        <div class="m-t1 m-b1 m-l1">
          <a-radio-group v-model:value="invoiceType" @change="changeInvoiceTypeChange">
            <a-radio :disabled="invoiceTypeDisabled" :value="1">增值税专用发票</a-radio>
            <a-radio :value="2">普票</a-radio>
          </a-radio-group>
        </div>
        <div><span style="color: red;font-family: SimSun, sans-serif;">*</span>发票类型：</div>
        <div class="m-t1 m-b1 m-l1">
          <a-radio-group v-model:value="invoiceTexture">
            <a-radio :value="2">电子</a-radio>
            <a-radio :value="1" :disabled="paperDisabled">纸质</a-radio>
          </a-radio-group>
        </div>
      </div>

      <div><span style="color: red;font-family: SimSun, sans-serif;">*</span>开票合同：</div>
      <div class="m-t1" v-if="sponsorDataList.length>0">
        <a-table size="small" :rowKey="(record, index) => { return index;}" bordered :pagination="false" :columns="sponsorColumns" :data-source="sponsorDataList">
          <template #settlementMode="{ record }">
            {{ record.settlementMode?.label }}
          </template>
          <template #contractTime="{record }">
           <div> {{ record.contractStartTime }}</div> {{record.contractEndTime}}
          </template>
          <template #settlementCycle="{record }">
            {{record.settlementCycle}}{{record.settlementMode.value == 2?'月':'天'}}
          </template>
          <template #fileUrls="{ record }">
            <a v-if="record.fileIds.length>0" @click="lookImg('',record.fileIds)">查看</a>
            <span v-else>无</span>
          </template>
          <template #add="{ record}">
            <a v-if="contractID ==record.id " @click="optionContract(record)">已选择</a>
            <a v-else @click="optionContract(record)">选择</a>
          </template>
        </a-table>
      </div>
      <div class="m-t1" v-if="!contractID">
        <a-upload v-model:file-list="fileList"  list-type="picture" multiple name="avatar" class="avatar-uploader" :show-upload-list="true" :before-upload="beforeUpload" :remove="removeChange" @preview="previewFile">
            <a-button :loading="loading"  type="primary">上传</a-button>
        </a-upload>
      </div>
      <div class="m-t1">
        <span>备注：</span>
        <a-textarea v-model:value="invoiceValue" placeholder="" :rows="2" />
      </div>
    </div>
    </a-modal>
    <!-- 添加车辆 -->
    <a-modal v-model:visible="additionVisible" title="添加车辆" width="85%" :footer="null">
      <div class="ADDcontentBox">
        <div class="tips">
          <span>已选择<span style="margin: 0 5px;color:red">{{ state.selectedRowKeys.length }}</span> 辆</span>
          <span class="m-l2">合计开票金额：<span style="color:red">￥{{ state.calculatedAmount.toFixed(2) }}</span></span>
          <a class="m-l4" @click="state.selectedRowKeys=[],state.calculatedAmount=0">清空选择</a>
        </div>
        <div>
          <SearchList :iptData="iptData" @resetBtn="resetBtn" :isShowReset="false" @searchAdd="getVal">
            <template #customFront>
              <a-select style="margin-right:10px" allowClear v-model:value="state.invoiceCarrier.value" :style="{ width: state.invoiceCarrier.width + 'px'}" :placeholder="state.invoiceCarrier.placeholder" @select="selectChnage">
                <a-select-option v-for="opt in state.invoiceCarrier.opt" :key="opt.id" :value="opt.id">{{ opt.subjectName }}</a-select-option>
              </a-select>
              <a-select style="margin-right:10px" allowClear v-model:value="state.taxItem.value" :style="{ width: state.taxItem.width + 'px'}" :placeholder="state.taxItem.placeholder" :disabled="state.invoiceCarrier.value?false:true">
                <a-select-option v-for="opt in state.taxItem.opt" :key="opt.id" :value="opt.id">{{ opt.label }}</a-select-option>
              </a-select>
            </template>
            <template #tail>
              <a-button @click="submit" type="primary">添加</a-button>
            </template>
          </SearchList>
        </div>
        <div>
          <a-table :loading="detailLoading" size="small"  rowKey="orderVehicleInvoiceId" bordered :row-selection="{ selectedRowKeys:state.selectedRowKeys, onChange: onSelectChange }" :columns="carColumns" :data-source="carInfoList">
            <template #vinNo="{ record }">
              {{ record.vinNo}} {{ record.brand }} {{ record.model }}
            </template>
            <template #taxRate="{ record }">
              <span v-if="record.taxRate"> {{  record.taxRate }}%</span>
            </template>
            <template #settlementStatus="{record}">
              {{ record.settlementStatus?.label }}
            </template>
            <template #invoiceAmt="{ record }">
              {{ record.invoiceAmt }}
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>

    <!-- 更换发票 -->
    <a-modal v-model:visible="invoiceTitleVisible" title="发票抬头" width="40%" :footer="null">
      <div class="invoiceTitle">
        <div v-for="item in listData" :key="item.id" style="width: 100%;" class="m-b2">
          <a-card :title="item.name" bodyStyle="padding: 0px;" headStyle="background: #E8F1FA;color: #344563;font-size: 16px;font-weight: 700" style="border: 1px solid #ABD2F6;">
            <template #extra><span :style="{'color':colorList[item.invoiceType.value]}" style="font-size: 16px;font-weight: 700;" v-if="item.invoiceType">{{ typeList[item.invoiceType.value] }}</span></template>
            <div class="flex wrap" style="padding: 20px;">
              <span style="width: 60%;" class="m-b1">名称：{{ item.name }}</span>
              <span class="m-b1">税号：{{ item.taxIdentNum }}</span>
              <span class="m-b1" style="width: 60%;">电话：{{ item.phone }}</span>
              <span class="m-b1">地址：{{ item.address }}</span>
              <span class="m-b1" style="width: 60%;">开户行：{{ item.bank }}</span>
              <span class="m-b1">银行账号：{{ item.account }}</span>
              <div style="width: 100%;height:50px" class="flex wrap">
                <span class="m-b1" style="width: 60%;">开票资料截图： <a v-if="item.screenshotIds.length > 0" @click="lookImg('screenshotIds',item)">查看</a></span>
                <span class="m-b1" v-if="item.invoiceType.value==3">一般纳税人资格证： <a v-if="item.taxpayerFileId" @click="lookImg('taxpayerFileId',item)">查看</a></span>
                <span style="width: 60%;" v-if="item.invoiceType.value==3">营业执照： <a v-if="item.licenseFileId" @click="lookImg('licenseFileId',item)">查看</a></span>
                <span v-if="item.invoiceType.value==2">组织机构代码： <a v-if="item.licenseFileId" @click="lookImg('licenseFileId',item)">查看</a></span>
              </div>
              <div style="width: 100%;" class="flex ju-end">
                <a v-if="mainID == item.id">已选择</a>
                <a v-else @click="selectMain(item)">选择</a>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </a-modal>
    <!-- 新增发票 -->
    <div>
      <NewModal @listChange="listChange" v-model:visible="newVisible" :customerId="userMsg.customerId"></NewModal>
    </div>
    <!-- 图片预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <!-- 自定义图片预览 -->
    <PictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></PictureViewer>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, createVNode } from 'vue'
import { start } from '@/api/system/bpm/instance'
import SearchList from '@/components/searchList/index.vue'
import { billInvoiceInvoiceId, vehicleInvoice, billInvoiceAddVehicle, InvoiceMainDetail, contractList, invoiceDeleteInfo } from '@/api/finance/finance.js'
import { invoiceList, fileUpload as upload } from '@/api/marketing/modList'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { provideUsableQuota } from '@/api/trans/finance/account'
import NewModal from '../newModal.vue'
import PictureViewer from '@/components/PictureViewer'

import { getSubjectList } from '@/api/trans/order'
import { useStore } from 'vuex'
const accept = ref('')
const store = useStore()
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  lookID: {
    type: String,
    default: ''
  },
  invoiceCarrierList: {
    type: Array,
    default: () => []
  },
  onlyDisplay:{
    type: Boolean,
    default: false
  }
})
const isSuccess = ref(false)
const lookImgID = ref([])
const visibleImgPic = ref(false)
const newVisible = ref(false)
const detailLoading = ref(false)
const previewImage = ref('')
const previewVisible = ref(false)
const loading = ref(false)
const fileList = ref([])
const fileListImg = ref([])
const paperDisabled = ref(false)
const invoiceTypeDisabled = ref(false)
const invoiceType = ref(0) // 开票类型
const invoiceTexture = ref(0) // 发票类型
const sponsorVisible = ref(false)
const additionVisible = ref(false)
const invoiceTitleVisible = ref(false)
const userMsg = ref({})
const dataList = ref([])
const carInfoList = ref([])// 车辆列表
const sponsorDataList = ref([])// 合同列表
const invoiceInfo = ref({})// 发票抬头信息
const invoiceValue = ref('') // 发票备注
const state = reactive({
  selectedRowKeys: [],
  calculatedAmount: 0,
  invoiceCarrier: {
    value: null,
    width: 200,
    placeholder: '开票主体',
    opt: []
  },
  taxItem: {
    value: null,
    width: 200,
    placeholder: '开票税率',
    opt: []
  }
})

const typeList = {
  1: '个人',
  2: '政府单位',
  3: '企业'
}
const colorList = {
  1: '#EE7D10',
  2: '#008005',
  3: '#0066CC'
}
const emit = defineEmits(['update:visible', 'handleOk'])
// 预览图片
const lookImg = (str, item) => {
  if (str) {
    if (typeof item[str] === 'string') {
      lookImgID.value = [item[str]]
      visibleImgPic.value = true
    } else {
      lookImgID.value = item[str]
      visibleImgPic.value = true
    }
  } else {
    lookImgID.value = item
    visibleImgPic.value = true
  }
}
const resetBtn = () => {
  state.invoiceCarrier.value = null
  state.taxItem.value = null
  searchMsg.value = {}
  searchAdd()
}
const searchMsg = ref({})
// 搜索
const getVal = (info) => {
  const subject = {
    invoiceCarrier: processingData(state.invoiceCarrier.opt, state.invoiceCarrier.value, 'subjectName'),
    taxItem: processingData(state.taxItem.opt, state.taxItem.value, 'taxItem'),
    taxRate: processingData(state.taxItem.opt, state.taxItem.value, 'taxRate')
  }
  const val = JSON.parse(JSON.stringify(info))
  val.startAddress = processingAddress(val.startAddress)
  val.endAddress = processingAddress(val.endAddress)
  Object.assign(val, subject)
  searchMsg.value = val
  getVehicleInvoice()
}
const selectChnage = (item) => {
  reqGetSubjectList(item)
  state.taxItem.value = null
}
// 处理数据
const processingAddress = (data) => {
  if (!data) return null
  const msg = {
    province: data[0],
    city: data[1],
    area: data[2]
  }
  return msg
}
const processingData = (data, id, label) => {
  let str = ''
  data.forEach(item => {
    if (item.id == id) {
      str = item[label]
    }
  })
  return str
}
// 获取主体列表
const reqGetSubjectList = (id, name) => {
  getSubjectList(id).then(res => {
    if (res.code !== 10000) return
    if (id == 0) {
      state.invoiceCarrier.opt = res.data
    } else {
      res.data.forEach(item => {
        item.label = item.subjectName + `【${item.taxItem}】`
      })
      state.taxItem.opt = res.data
    }
  })
}
// 删除明细
const confirm = (item) => {
  invoiceDeleteInfo(userMsg.value.id, item.detailsId).then(res => {
    if (res.code !== 10000) return
    reqBillInvoiceInvoiceId()
    message.success(res.msg)
  })
}
// 发起开票
const handleOk = () => {
  if (loading.value) return
  if (isSuccess.value) return
  if (!mainID.value) {
    message.warn('请先添加发票抬头')
    return
  }
  if (!invoiceType.value) {
    message.warn('请选择开票类型')
    return
  }
  if (!invoiceTexture.value) {
    message.warn('请选择发票类型')
    return
  }
  if (!contractID.value && fileListImg.value.length == 0) {
    message.warn('请选择合同或者上传照片')
    return
  }
  const num = parseInt(availableCredit.value) - parseInt(userMsg.value.amt)
  const str = num < 0 ? '主体本月开票额度不足，有可能影响开票速度！' : ''
  Modal.confirm({
    title: () => '确认要开票吗?',
    icon: () => createVNode(ExclamationCircleOutlined),
    content: () => createVNode('div', { style: 'color:red;' }, str),
    onOk () {
      isSuccess.value = true
      const contractFileIds = []
      fileListImg.value.forEach(item => {
        contractFileIds.push(item.id)
      })
      const msg = {
        bizParams: {
          invoiceApplyId: userMsg.value.id, // 申请发票ID
          customerName: userMsg.value.customerName, // 客户名
          customerId: userMsg.value.customerId, // 客户id
          invoiceCarrier: userMsg.value.invoiceCarrier, // 销售方（开票主体）
          taxRate: userMsg.value.taxRate ? parseInt(userMsg.value.taxRate) : '', // 税率
          taxItem: userMsg.value.taxItem, // 税目
          customerInvoiceId: mainID.value, // 选中的发票抬头
          invoiceTypeName: invoiceInfo.value.invoiceType ? invoiceInfo.value.invoiceType.label : '', // 客户发票类型
          purchasingUnit: invoiceInfo.value.name, // 抬头名称
          taxIdentNum: invoiceInfo.value.taxIdentNum, // 税号
          invoiceMethodType: invoiceTexture.value, // 发票方式(电子||纸质)
          ticketCategoryType: invoiceType.value, // 票类
          customerContractId: contractID.value, // 选中的合同ID
          contractFileIds: contractFileIds, // 额外的合同
          invoiceAmt: userMsg.value.amt, // 发票金额
          remark: invoiceValue.value// 备注
        },
        type: 6
      }
      start(msg).then(res => {
        if (res.code !== 10000) return
        sponsorVisible.value = false
        message.success('发起开票成功')
        emit('handleOk')
        cancel()
      }).finally(() => {
        isSuccess.value = false
      })
    },
    onCancel () {

    },
    class: 'test'
  })
}
// 选择合同
const contractID = ref('')
const optionContract = (item) => {
  if (item.id == contractID.value) {
    contractID.value = ''
    accept.value = ''
    return
  }
  contractID.value = item.id
  fileListImg.value = []
  fileList.value = []
}
// 更换发票抬头
const selectMain = (item) => {
  if (item.invoiceType.value !== 3) {
    invoiceType.value = 2
    invoiceTypeDisabled.value = true
  } else {
    invoiceType.value = 0
    invoiceTypeDisabled.value = false
  }
  invoiceInfo.value = item
  mainID.value = item.id
  invoiceTitleVisible.value = false
}
// 添加车辆
const submit = () => {
  if (isSuccess.value) return
  if (state.selectedRowKeys.length == 0) {
    message.warn('请选择车辆')
    return
  }
  const list = []
  state.selectedRowKeys.map(key => {
    carInfoList.value.forEach(item => {
      if (item.orderVehicleInvoiceId == key) {
        list.push(item)
      }
    })
  })
  const applyDetails = []
  if (dataList.value.length > 0) {
    if (!list.every(item => item.invoiceCarrier == dataList.value[0]?.invoiceCarrier)) {
      message.warn('开票主体不一致，请从新选择')
      return
    }
    if (!list.every(item => item.taxRate == dataList.value[0]?.taxRate)) {
      message.warn('开票税率不一致，请从新选择')
      return
    }
  } else {
    if (!list.every(item => item.invoiceCarrier == list[0]?.invoiceCarrier)) {
      message.warn('开票主体不一致，请从新选择')
      return
    }
    if (!list.every(item => item.taxRate == list[0]?.taxRate)) {
      message.warn('开票税率不一致，请从新选择')
      return
    }
  }

  isSuccess.value = true
  list.map(item => {
    applyDetails.push({
      applyAmt: item.invoiceAmt,
      orderVehicleInvoiceId: item.orderVehicleInvoiceId
    })
  })
  const obj = {
    id: props.lookID,
    applyDetails: applyDetails
  }
  billInvoiceAddVehicle(obj).then(res => {
    if (res.code !== 10000) return
    additionVisible.value = false
    state.selectedRowKeys = []
    reqBillInvoiceInvoiceId()
    message.success(res.msg)
  }).finally(() => {
    isSuccess.value = false
  })
}
const changeInvoiceTypeChange = (e) => {
  if (e.target.value == 1 && !invoiceInfo.value.taxpayerFileId) {
    message.warning('请补充纳税人资格证照片,如需修改请到合作客户发票抬头处调整！')
    invoiceType.value = ''
  }
}
const onSelectChange = (selectedRowKeys, e) => {
  state.calculatedAmount = calculatedAmount(e, 'invoiceAmt')
  state.selectedRowKeys = selectedRowKeys
}
const calculatedAmount = (arr, type) => {
  if (!arr) return ''
  let num = 0
  arr.forEach(item => {
    num += Number(item[type])
  })
  return num
}
const cancel = () => {
  emit('update:visible', false)
}
// 图片预览
const previewFile = async (file) => {
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj))
  }
  previewImage.value = file.url || file.preview
  previewVisible.value = true
}
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
// 图片上传
const beforeUpload = (file) => {
  updateBatch(file)
  return false
}
// 删除图片
const removeChange = (file) => {
  fileListImg.value.forEach((item, index) => {
    if (item.name === file.originFileObj.name) fileListImg.value.splice(index, 1)
  })
  if (fileListImg.value.length == 0) {
    accept.value = ''
  }
}
const key = 'updatable'
const updateBatch = (file) => {
  loading.value = true
  message.loading({ content: '上传中..', key })
  const formData = new FormData()
  formData.append('file', file)
  upload(formData).then((res) => {
    loading.value = false
    if (res.code === 10000) {
      fileListImg.value.push(res.data)
      message.success({ content: '上传成功', key, duration: 2 })
    } else {
      message.error({ content: '上传失败', key, duration: 2 })
    }
  })
}
// 更换抬头
const changeTitle = () => {
  invoiceTitleVisible.value = true
  reqInvoiceList()
}
// 新增抬头
const newTitle = () => {
  newVisible.value = true
}
// 发起开票
const sponsorAdd = () => {
  if (dataList.value.length == 0) {
    message.warn('请先添加车辆，再发起开票')
    return
  }
  sponsorVisible.value = true
  reqInvoiceMainDetail(userMsg.value.customerId)
  reqContractList(userMsg.value.customerId)
}
// 添加车辆
const addOrden = () => {
  additionVisible.value = true
  getVehicleInvoice()
}
// 获取车辆列表
const getVehicleInvoice = () => {
  detailLoading.value = true
  const msg = {
    customerId: userMsg.value.customerId,
    current: 1,
    size: 10
  }
  Object.assign(msg, searchMsg.value)
  vehicleInvoice(msg).then(res => {
    detailLoading.value = false
    if (res.code !== 10000) return
    carInfoList.value = res.data
  })
}
const reqBillInvoiceInvoiceId = () => {
  detailLoading.value = true
  billInvoiceInvoiceId(props.lookID).then(res => {
    detailLoading.value = false
    if (res.code == 10000) {
      if (res.data.isPaper.value !== 1) {
        paperDisabled.value = true
        invoiceTexture.value = 2
      }
      userMsg.value = res.data
      dataList.value = res.data.details
      reqInvoiceList()
      reqProvideUsableQuota(res.data.id)
    } else {
      userMsg.value = {}
      dataList.value = []
    }
  })
}
const mainID = ref(0)
const reqInvoiceMainDetail = (id) => {
  InvoiceMainDetail(id).then((res) => {
    if (res.code !== 10000) return
    if (res.data) {
      if (res.data.invoiceType.value !== 3) {
        invoiceType.value = 2
        invoiceTypeDisabled.value = true
      }
      invoiceInfo.value = res.data
      mainID.value = res.data.id
    } else {
      if (listData.value.length > 0) {
        const msg = listData.value[0]
        if (msg.invoiceType.value !== 3) {
          invoiceType.value = 2
          invoiceTypeDisabled.value = true
        }
        invoiceInfo.value = msg
        mainID.value = msg.id
      }
    }
  })
}
const reqContractList = (id) => {
  contractList(id).then((res) => {
    if (res.code !== 10000) return
    sponsorDataList.value = res.data
  })
}
const listData = ref([])
const reqInvoiceList = () => {
  invoiceList(userMsg.value.customerId, {
    isEnable: 1
  }).then(res => {
    if (res.code !== 10000) return
    listData.value = res.data
  })
}
const listChange = () => {
  if (listData.value.length > 0) return
  reqInvoiceList()
  reqInvoiceMainDetail(userMsg.value.customerId)
}
const availableCredit = ref('')
const reqProvideUsableQuota = (id) => {
  provideUsableQuota(id).then(res => {
    availableCredit.value = res.data
  })
}
onMounted(() => {
  reqBillInvoiceInvoiceId()
  state.invoiceCarrier.opt = props.invoiceCarrierList
  iptData.value[1].opt = store.state.app.cityDict
  iptData.value[2].opt = store.state.app.cityDict
})

const columns = ref([
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key: 'customerName',
    align: 'center'

  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center'
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    key: 'orderStatus',
    align: 'center'
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key: 'vinNo',
    align: 'center',
    slots: {
      customRender: 'vinNo'
    }
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center'
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    key: 'orderId',
    align: 'center'
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    key: 'salesman',
    align: 'center'
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align: 'center'
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key: 'settlementStatus',
    slots: { customRender: 'settlementStatus' },
    align: 'center'
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key: 'invoiceCarrier',
    align: 'center'
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    key: 'taxRate',
    align: 'center',
    slots: { customRender: 'taxRate' }
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    key: 'taxItem',
    align: 'center'
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmt',
    key: 'invoiceAmt',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    key: 'add',
    align: 'center',
    slots: { customRender: 'add' }
  }
])
const sponsorColumns = ref(
  [
    {
      title: '合同名称',
      dataIndex: 'contractName',
      key: 'contractName',
      align: 'center'

    },
    {
      title: '合同编号',
      dataIndex: 'contractCode',
      key: 'contractCode',
      align: 'center',
      width: '100px'
    },
    {
      title: '结算方式',
      dataIndex: 'settlementMode',
      key: 'settlementMode',
      slots: { customRender: 'settlementMode' },
      align: 'center'
    },
    {
      title: '结算周期',
      dataIndex: 'settlementCycle',
      slots: { customRender: 'settlementCycle' },
      align: 'center'
    },
    {
      title: '起止日期',
      dataIndex: 'contractTime',
      key: 'contractTime',
      slots: { customRender: 'contractTime' },
      align: 'center',
      width:150
    },
    {
      title: '签约时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center'
    },
    {
      title: '合同照片',
      dataIndex: 'fileUrls',
      key: 'fileUrls',
      align: 'center',
      slots: { customRender: 'fileUrls' }
    },
    {
      title: '操作',
      dataIndex: 'taxRate',
      width: '80px',
      align: 'center',
      slots: { customRender: 'add' }
    }
  ]
)
const carColumns = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key: 'vinNo',
    align: 'center',
    slots: { customRender: 'vinNo' }
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key: 'customerName',
    align: 'center',
    width: '100px'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center'
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    key: 'orderStatus',
    align: 'center'
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center'
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    key: 'orderId',
    align: 'center'
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    key: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align: 'center'
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key: 'settlementStatus',
    align: 'center',
    slots: { customRender: 'settlementStatus' }
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key: 'invoiceCarrier',
    align: 'center'
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    key: 'taxRate',
    align: 'center',
    slots: { customRender: 'taxRate' },
    width: 80
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    key: 'taxItem',
    align: 'center'
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmt',
    key: 'invoiceAmt',
    align: 'center',
    slots: { customRender: 'invoiceAmt' }
  }
])
const iptData = ref([
  {
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',
    width: 150
  },
  {
    type: 'cascader',
    placeholder: '起始地',
    value: null,
    prop: 'startAddress',
    changeOn: true,
    opt: [],
    width: 150
  },
  {
    type: 'cascader',
    placeholder: '目的地',
    value: null,
    prop: 'endAddress',
    changeOn: true,
    opt: [],
    width: 150
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: 150
  },
  {
    type: 'select',
    placeholder: '结算状态',
    value: null,
    prop: 'settlementStatus',
    width: 150,
    opt: [
      { name: '未结算', id: 0 },
      { name: '部分结算', id: 1 },
      { name: '已结算', id: 2 }
    ]
  }

])
</script>

<style lang="less" scoped>
.m-b0-5 {
  margin-bottom: 5px;
}
.infoBox {
  width: 100%;
  padding: 25px 10px;
  border: 1px solid rgb(193, 199, 208);
}

.contentBox {
  margin-top: 60px;
  .tips {
    height: 40px;
  }
}

.cardBox {
  border: 1px solid rgb(215, 215, 215);
  background: #f6f6f6;
  .title {
    background: #f4f7fc;
    border-bottom: 1px solid rgb(215, 215, 215);
    padding: 10px 20px;
  }
}
:deep(.navBox) {
  padding: 0;
}

.ADDcontentBox {
  .tips {
    height: 40px;
  }
}

.invoiceTitle {
  height: 800px;
  overflow-y: auto;
}
</style>
